import { postContactInfo } from './mainActions';
import { clearHttp } from './httpAction';
import { CLEAR_NOTIFICATION, SET_TABLE_INDEX } from './types';

const clearNotification = (  ) => ( dispatch : Function ) => {
	dispatch({ type: CLEAR_NOTIFICATION })
};

const setTableIndex = (index: string) => ( dispatch : Function ) => {
	dispatch({ type: SET_TABLE_INDEX, payload: index })
}

export { 
//MAIN-ACTIONS
postContactInfo, clearNotification, setTableIndex,
  
//HTTP-ACTIONS
clearHttp
};