//Generic
export const AXIOS_REQUEST = 'AXIOS_REQUEST';
export const AXIOS_REQUEST_SUCCESS = 'AXIOS_REQUEST_SUCCESS';
export const AXIOS_REQUEST_FAILURE = 'AXIOS_REQUEST_FAILURE';
export const SET_ADMIN_AUTHENTICATED = 'SET_ADMIN_AUTHENTICATED';
export const SET_ADMIN_UNAUTHENTICATED = 'SET_ADMIN_UNAUTHENTICATED';
export const SET_HTTP_ACTION = 'SET_HTTP_ACTION';
export const SET_HTTP_STATUS = 'SET_HTTP_STATUS';
export const SET_HTTP_METHOD = 'SET_HTTP_METHOD';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_HTTP = 'CLEAR_HTTP';
export const SET_TABLE_INDEX = 'SET_TABLE_INDEX';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
