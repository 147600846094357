import React from 'react';
import { url } from '../../enums/Route'

export interface IUrl {
    id : number;
    PageRender : React.ReactElement | null;
    path: string;
}

export const Page404 = React.lazy(()=>import('../404'));
const LandingPage = React.lazy(() => import('components/landing'));
const Instructions = React.lazy(() => import('components/instructions'));
const Solutions = React.lazy(() => import('components/solutions'));
const Contact = React.lazy(() => import('components/contact'));

export const route:Array<IUrl> = [
    {   id : 1,
        PageRender : <LandingPage />,
        path : url.LANDING_PAGE,
    },
    {   id : 2,
        PageRender : <Instructions />,
        path : url.INSTRUCTIONS,
    },
    {   id : 3,
        PageRender : <Solutions />,
        path : url.SOLUTIONS,
    },
    {   id : 4,
        PageRender : <Contact />,
        path : url.CONTACT,
    },
    {   id : 11,
        PageRender : <Page404 />,
        path : url.PAGE404,
    },
];