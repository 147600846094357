
export enum url {
    LANDING_PAGE = "/",
    LOGIN = '/login',
    INSTRUCTIONS = '/quick-info',
    SOLUTIONS = '/solutions',
    CONTACT = '/contact-us',
    PAGE404 = '',
};

export enum pageName {
    OVERVIEW = 'Overview',
    APPLICATIONS = 'Applications',
    USERS = 'User Management',
    PROFILE = 'Profile',
    ORGANIZATION = 'Organization',
    BILLING = 'Billing',
    API_BUNDLES = 'API Bundles',
    API_CONFIGS = 'API Configurations',
    API_CONFIG_GROUPS = 'API Configuration Groups',
    SUBSCRIPTIONS = 'Subscription',
    CLIENT_SUBSCRIPTIONS = 'Client Subscriptions',
    API_DOCUMENTATION = 'Documentation',
    PERMISSIONS = 'Permissions',
    ROLES = 'Roles',
}

