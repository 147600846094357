import React, { FC } from 'react';
import {Routes, Route } from 'react-router-dom';
import { route } from 'utils/route';

const ARoutes: FC = () => {
    
    return(
        <>
            <Routes>
                {route!?.map( (item) => {
                    return(
                        <Route key={ item.id } path={item.path} element={item.PageRender} />
                    ) 
                })}
            </Routes>
        </>
    );
};

export default ARoutes;